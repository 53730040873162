import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = 'xsact-gui';

  // constructor(@Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) _document: Document) {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const recaptchaElement = _document.createElement('script');
  //     recaptchaElement.src = `https://www.google.com/recaptcha/enterprise.js?render=${environment.recaptchaKey}`;
  //     recaptchaElement.async = true;
  //     _document.body.appendChild(recaptchaElement);
  //   }
  // }
}
