import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environments/environment';
import { init as sentryInit, browserTracingIntegration, replayIntegration } from '@sentry/angular';


sentryInit({
  dsn: 'https://f3d540173ffe4f4cb80427d887566e80@sentry.io/1291403',

  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    browserTracingIntegration(),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    replayIntegration(),
  ],

  // TODO avoid enabling when just running "npm start" to avoid a lot of emails
  enabled: (location && location.hostname.indexOf('localhost') === -1),
  maxBreadcrumbs: 50,
//  debug: !environment.production,
  release : environment.gitHash,
  // less tracing on production than staging and dev
  tracesSampleRate: environment.production ? 0.5 : 1.0,

  // Capture Replay for 1% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 1.0,
  tracePropagationTargets: ['localhost', 'https://test.transact.io', 'https://transact.io', 'https://www.transact.io'],
});



if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
