// import * as Debug_ from 'debug';
// const Debug = Debug_;
// const debug = Debug('shared:TransactExceptionHandler');
import { ErrorHandler } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';

import { captureException } from '@sentry/angular';

/*
Sentry.init({
  dsn: 'https://f3d540173ffe4f4cb80427d887566e80@sentry.io/1291403',
  environment: environment.production ? 'Prod' : 'Staging',
  release: environment.gitHash,
  debug: !environment.production,
});
*/

export class TransactErrorHandler implements ErrorHandler {
  constructor(private http: HttpClient) {}

  handleError(err: any) {
    try {
      if (typeof err === 'object' && 'status' in err && err.status >= 400 && err.status < 500) {
        // Don't report to sentry if it was an http 4xx error, because the user just wasn't logged in or didn't have permissions
        return;
      } else {
        // do something with the exception
        console.error('TRANSACT EXCEPTION handleError: ', err);
        this.sendError2Transact(err);
      }
    } catch (sendError) {
      console.error('error sendError2Transact: ', sendError);
    }

    try {
      console.debug('call sentry:', err);
      captureException(err);
    } catch (sentryError) {
      console.error('error sending to sentry', sentryError);
    }

  }
  call(err: any) {
    console.error('TRANSACT EXCEPTION call: ', err);
    this.sendError2Transact(err);
  }

  private sendError2Transact(err: any) {
    let params = new HttpParams();
    // debug('adding err:', err);

    if (err.message) {
      params = params.set('error_message', JSON.stringify(err.message));
    }
    if (err.stack) {
      params = params.set('error_stack', JSON.stringify(err.stack));
    }
    if (err.originalError) {
      params = params
        .set('error_message', JSON.stringify(err.originalError.message))
        .set('error_stack', JSON.stringify(err.originalError.stack));
    }

    // debug('adding document location:');
    params = params.set('document_location', JSON.stringify((window as any).document.location));

    // debug('sending exception post', params);

    this.http
      .post('/api/util/exception', params)
      .toPromise()
      .catch((postErr: any) => {
        /* eslint-disable */
        // debug('post err:', err);
      })
      .then((resp: HttpResponse<any>) => {
        // debug('response', resp);
      });
  }
}
